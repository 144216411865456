import React, {useState, useEffect} from 'react';
import { Link } from 'gatsby';
import Layout from "../../components/layout";
import Loader from "../../components/loading";
import { Container, Row, Col, Form, Button, Alert} from 'react-bootstrap';
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';
import LocalStorage from '../../components/localStorage';

import { navigate } from 'gatsby';

const ChangePassword = ({location}) => {
  const [showLoader, setShowLoader] = useState(false)
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [processSent, setProcessSent] = useState(false)

  const sendForgotPasswordEmail = (e) => {
    setShowLoader(true)
    const url=`${constants.BACKEND_URL}/publicCustomers/forgotPassword`
    const data={
      email
    }
    Fetch.post(url, JSON.stringify(data)).then(data => {
      if (!data.status) {
        setShowError(true);
        setErrorMessage(data.msg);
        setShowLoader(false)
        return;
      }
      if (data.token && data.validUntil) {
        setShowError(false);
        setErrorMessage('');
        setProcessSent(true);
      }
      setShowLoader(false)
    })
  }
  

  useEffect(() => {
    LocalStorage.removeItem('jwt','')
    return () => {
    }
  }, [])

  const handleItemChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const emailElement = document.querySelector('#email')
    if (emailElement.checkValidity() === false) {
      if (emailElement.validity.valueMissing) {
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Please enter your email address"
      } else if (emailElement.validity.typeMismatch){
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Invalid email format"
      }
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    
    setShowError(false);
    setErrorMessage('');
    setValidated(false);
    sendForgotPasswordEmail()
  };
  
  return (
    <Layout>
      <Container>
          <Loader show={showLoader}></Loader>
          
          {showError && (
            <>
              <Alert dismissible variant="danger" onClose={()=>{setShowError(false)}}>{errorMessage}</Alert>
              <Link to={'/login'}>Return to Login</Link>
            </>
          )}

          {processSent && (
            <>
              <Alert variant="info">Please check your email inbox for the reset link.</Alert>
              <Link to={'/login'}>Return to Login</Link>
            </>
          )}

          {!processSent && !errorMessage && (
            <>
            <Row>
              <Col className="text-center">
                <h5>Forgot Password</h5>   
                <h6>Enter your email address to receive a link to reset your password</h6>       
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group as={Row} >
                <Form.Label column sm={2}>
                  Email address
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    id="email"
                    required
                    type="email" 
                    placeholder="Email address" 
                    value={email} 
                    onChange={handleItemChange}
                  />
                  <Form.Control.Feedback id="newPwd" type="invalid">Please enter your email address</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Button className="mr-1 mb-3" variant="outline-primary" type="submit">Submit</Button>
              <Button className="mr-1 mb-3" variant="danger" onClick={()=>{navigate('/login')}}>Cancel</Button>
            </Form>
            </>
          )}

          
      </Container>
    </Layout>
  )
}

export default ChangePassword 